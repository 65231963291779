import React, { useContext, useState } from 'react'
import { Grid } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import {
  LaakariButton,
  InfoContainer,
  ShadowContainer,
  SubHeaderH2,
  ContainerHeaderH1
} from '../../components/styled/uiComponents'
import { UserContext } from '../../context'
import { parseDate } from '../../utils/utils'
import { isCurrentAnyMemberOfFMAOrOtherOrganization } from '../../utils/membershipChecks'
import MobileTitle from './MobileTitle'
import Divider from './Divider'

const PersonalInfo = () => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(!isMobile)

  const currentJob = user?.employments?.find((job: any) => job.els_paatoimi === true)
  const isMember = isCurrentAnyMemberOfFMAOrOtherOrganization(user)

  const handleNavigateEdit = () => {
    navigate('/updateInfo')
  }

  return (
    <>
      <Divider id='info' isMobile={isMobile} />
      <InfoContainer>
        <Grid container>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            {isMobile ? (
              <MobileTitle isOpen={isOpen} setIsOpen={setIsOpen} title='Tietoni' />
            ) : (
              <>
                <Grid item xs={12} md={9} lg={10}>
                  <ContainerHeaderH1>Perustiedot</ContainerHeaderH1>
                </Grid>
                <Grid item xs={12} md={3} lg={2} style={{ textAlign: 'right' }}>
                  {isMember && user && !isMobile && (
                    <LaakariButton onClick={handleNavigateEdit}>Muokkaa tietoja</LaakariButton>
                  )}
                </Grid>
              </>
            )}
          </Grid>
          {isOpen && (
            <Grid item xs={12}>
              <ShadowContainer>
                <Grid
                  container
                  direction={isMobile ? 'column' : 'row'}
                  alignItems='space-between'
                  spacing={3}>
                  {isMember && (
                    <>
                      <Grid item xs={4}>
                        <SubHeaderH2>Henkilötiedot</SubHeaderH2>
                        <h3>Nimi</h3>
                        <p>{user?.firstname}</p>

                        <h3>Matkapuhelin</h3>
                        <p>{user?.mobilephone}</p>

                        <h3>Sähköposti</h3>
                        <p>{user?.emailaddress1}</p>

                        <h3>Kansalaisuus</h3>
                        <p>{user?.address1_country?.els_koodinnimi}</p>

                        <h3>Cursus</h3>
                        <p>{user?.els_cursus?.els_oppilaitos?.els_koodinnimi}</p>
                      </Grid>

                      <Grid item xs={4}>
                        <SubHeaderH2>Työpaikkatiedot</SubHeaderH2>

                        <h3>Työpaikka</h3>
                        <p>{currentJob?.els_nimi}</p>

                        <h3>Nimike</h3>
                        <p>{currentJob?.ammattinimike?.els_koodinnimi}</p>

                        <h3>Aloituspäivämäärä</h3>
                        <p>{currentJob?.els_alkupvm && parseDate(currentJob?.els_alkupvm)}</p>
                      </Grid>

                      <Grid item xs={4}>
                        <SubHeaderH2>Postiosoitteet</SubHeaderH2>

                        <h3>Postiosoite</h3>
                        <p>{user?.address1_line1}</p>
                        <p>{user?.address1_postalcode}</p>
                        <p>{user?.address1_city}</p>
                        <p>{user?.address1_country_els_koodinnimi}</p>

                        <h3>Postituskieli</h3>
                        <p>{user?.postikieli?.value}</p>

                        <h3>Osoitteen luovutus</h3>
                        <p>{user?.markkinoinninesto?.value}</p>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    {isMember && user && isMobile && (
                      <LaakariButton onClick={handleNavigateEdit}>Muokkaa tietoja</LaakariButton>
                    )}
                  </Grid>
                </Grid>
              </ShadowContainer>
            </Grid>
          )}
        </Grid>
      </InfoContainer>
    </>
  )
}

export default PersonalInfo
