import React, { useContext, useState } from 'react'

import { Grid, Typography } from '@mui/material'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

import {
  InfoContainer,
  LaakariButton,
  ShadowContainer,
  ShadowTableRow,
  SubHeaderH2
} from '../../components/styled/uiComponents'
import { UserContext } from '../../context'
import { parseDate } from '../../utils/utils'
import { isCurrentAnyMemberOfFMAOrOtherOrganization } from '../../utils/membershipChecks'
import Divider from './Divider'
import MobileTitle from './MobileTitle'

const Merchandice = () => {
  const { user, merchState } = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(!isMobile)
  const navigate = useNavigate()
  const merchandice = user?.memberProducts

  const handleNavigateForm = () => {
    navigate('/merch')
  }

  const visibleMerchandice: any[] = merchState || merchandice

  const isMember = isCurrentAnyMemberOfFMAOrOtherOrganization(user)

  const handleOpenMemberCard = () => {
    window.open('/memberCard')
    console.log('hello')
  }

  const MobileView = (
    <>
      <Divider id='merch' isMobile={isMobile} />
      <InfoContainer>
        <Grid container>
          <Grid container alignItems='center'>
            <MobileTitle
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title='Jäsentuotteet ja Lääkärilehti'
            />
          </Grid>
          {isOpen && (
            <>
              <Grid item xs={12} md={4} style={{ textAlign: 'right', marginBottom: 4 }}>
                <LaakariButton onClick={handleNavigateForm}>Muokkaa tuotetilauksia</LaakariButton>
                <LaakariButton
                  sx={{ mt: '1rem' }}
                  disabled={!isMember}
                  onClick={handleOpenMemberCard}>
                  Avaa Jäsenkortti
                </LaakariButton>
              </Grid>
              {visibleMerchandice.map((product: any, i) => (
                <ShadowTableRow key={`merchProduct-${i}`}>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Jäsentuote</Typography>
                    <p>{product.visibleName}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Tilauspäivämäärä</Typography>
                    <p>{product.els_tilauspaiva && parseDate(product.els_tilauspaiva)}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Peruutuspäivämäärä</Typography>
                    <p>{product.els_peruutuspaiva && parseDate(product.els_peruutuspaiva)}</p>
                  </Grid>
                </ShadowTableRow>
              ))}
            </>
          )}
        </Grid>
      </InfoContainer>
    </>
  )

  const DesktopView = (
    <>
      <Divider id='merch' isMobile={isMobile} />
      <InfoContainer>
        <Grid container>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={1}>
            <Grid item xs={12} md={6} lg={8}>
              <SubHeaderH2>Jäsentuotteet ja Lääkärilehti</SubHeaderH2>
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <LaakariButton disabled={!isMember} onClick={handleOpenMemberCard}>
                Avaa Jäsenkortti
              </LaakariButton>
            </Grid>
            <Grid item xs={12} md={3} lg={2} style={{ textAlign: 'right' }}>
              <LaakariButton onClick={handleNavigateForm} style={{ whiteSpace: 'nowrap' }}>
                Muokkaa tuotetilauksia
              </LaakariButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ShadowContainer>
              <Grid container justifyContent='flex-start'>
                <Grid item xs={12} md={4}>
                  <h3>Jäsentuote</h3>
                </Grid>
                <Grid item xs={12} md={4}>
                  <h3>Tilauspäivämäärä</h3>
                </Grid>
                <Grid item xs={12} md={4}>
                  <h3>Peruutuspäivämäärä</h3>
                </Grid>

                {merchState
                  ? merchState?.map((product: any, i: number) => (
                      <React.Fragment key={i}>
                        <Grid item xs={12} md={4} key={i}>
                          <p>{product.visibleName}</p>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <p>{product.els_tilauspaiva && parseDate(product.els_tilauspaiva)}</p>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <p>{product.els_peruutuspaiva && parseDate(product.els_peruutuspaiva)}</p>
                        </Grid>
                      </React.Fragment>
                    ))
                  : merchandice?.map((product: any, i: number) => (
                      <React.Fragment key={i}>
                        <Grid item xs={12} md={4} key={i}>
                          <p>{product.visibleName}</p>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <p>{product.els_tilauspaiva && parseDate(product.els_tilauspaiva)}</p>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <p>{product.els_peruutuspaiva && parseDate(product.els_peruutuspaiva)}</p>
                        </Grid>
                      </React.Fragment>
                    ))}
              </Grid>
            </ShadowContainer>
          </Grid>
        </Grid>
      </InfoContainer>
    </>
  )
  return isMobile ? MobileView : DesktopView
}

export default Merchandice
