import React from 'react'
import { Grid, Typography } from '@mui/material'

import PageHeader from '../../components/PageHeader'
import { ShadowTableRow } from '../../components/styled/uiComponents'
import ButtonToFrontpage from '../../components/ButtonToFrontpage'

interface Props {
  formName: string
  longInfo?: string
  subUrl?: string
}

const FormSentConfirmation: React.FC<Props> = ({ formName, longInfo, subUrl }) => {
  return (
    <>
      <PageHeader />
      <div style={{ padding: '3vh' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Typography variant='h3' color='primary'>
              {formName}
            </Typography>
          </Grid>
          <Grid container item xs={12} md={8}>
            <ShadowTableRow>
              <p>Kiitos.</p>
              {longInfo ? (
                longInfo
              ) : (
                <p>Hakemus on lähetetty ja siirtyy seuraavaksi toimihenkilön käsiteltäväksi.</p>
              )}
            </ShadowTableRow>
            <Grid container direction='row-reverse' justifyContent='flex-start'>
              <Grid item xs={12} md={3} style={{ textAlign: 'right' }}>
                <ButtonToFrontpage subUrl={subUrl} formName={formName} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default FormSentConfirmation
